import React from "react";

const PipeDriveForm = ({ formId, height = 750}) => {
  if (!formId) return null;
  return (
    <div className="pipedriveWebForms">
      <style jsx>
        {`
          .pipedriveWebForms {
            width: 99%;
            overflow: hidden;
            height: ${height}px;
            position: relative;
          }

          .pipedriveWebForms iframe {
            border: none;
            overflow: hidden;
            width: 100%;
            max-width: 768px;
            height: 100%;
            position: relative;
          }
        `}
      </style>
      <iframe
        src={`https://webforms.pipedrive.com/f/${formId}?embeded=1`}
        scrolling="no"
        seamless="seamless"
      />
    </div>
  )
}

export default PipeDriveForm;
